<template>
  <div class="x">
    <Toolbar class="p-mb-4">
      <template #left>
        اضافه جديد
      </template>
      <template #right>
        <Button
          icon="pi pi-times"
          class="p-button-danger"
          @click="$router.push('/admin/certificates')"
        />
      </template>
    </Toolbar>
    <div class="form-card row">
      <div class="col-md-12">
        <div class="mb-3 text-center">
          <div class="align-items-center">
            <b-form-file
              class="mb-3"
              accept="image/*"
              @change="previewImage"
            ></b-form-file>
            <div>
              <div class="card-body-xxx">
                <img
                  :src="body.image"
                  class="profile-pic psizwxxx align-items-center"
                  @error="$errorHandlerCertificate"
                />
                <div
                  style="position: absolute; top: 0cm;width: 19cm;margin-left: auto;margin-right: auto;left: 0;right: 0;text-align: center;"
                >
                  <img
                    src="../../../assets/p.png"
                    :style="
                      `width: ${body.imageWidth}px; height: ${body.imageHeight}px;left: ${body.imageLeft}px;position: absolute;top: ${body.imageTop}px;`
                    "
                    alt=""
                    v-if="inSelectedArray('image')"
                  />

                  <p
                    :style="
                      `font-size: 24px;position: absolute;right: ${body.academieRight}px;top: ${body.academieTop}px;`
                    "
                    v-if="inSelectedArray('academie')"
                  >
                    اكاديمية برو سكور
                  </p>
                  <p
                    :style="
                      `font-size: 24px;position: absolute;right: ${body.nameRight}px;top: ${body.nameTop}px;`
                    "
                    v-if="inSelectedArray('name')"
                  >
                    محمد احمد محمد
                  </p>
                  <p
                    :style="
                      `font-size: 24px;position: absolute;right: ${body.userTypeRight}px;top: ${body.userTypeTop}px;`
                    "
                    v-if="inSelectedArray('userType')"
                  >
                    مدرب
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-md-12">
        <div class="mb-3">
          <label for="name" class="form-label">
            الاسم
          </label>
          <input
            type="text"
            class="form-control"
            id="name"
            v-model="body.name"
          />
        </div>

        <div class="mb-3 text-right">
          <b-form-group
            label="البيانات المراد اظهارها"
            v-slot="{ ariaDescribedby }"
          >
            <b-form-checkbox-group
              id="checkbox-group-2"
              v-model="body.selected"
              multiple
              :aria-describedby="ariaDescribedby"
              name="selected[]"
            >
              <b-form-checkbox value="image">الصوره</b-form-checkbox>
              <b-form-checkbox value="academie">الاكاديميه</b-form-checkbox>
              <b-form-checkbox value="name">الاسم</b-form-checkbox>
              <b-form-checkbox value="userType">الصفه</b-form-checkbox>
            </b-form-checkbox-group>
          </b-form-group>
        </div>

        <table class="table" v-if="inSelectedArray('image')">
          <thead>
            <tr>
              <th>الاعداد</th>
              <th>Top</th>
              <th>Left</th>
              <th>Width</th>
              <th>Height</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <th>الصوره</th>
              <td style="direction: ltr;">
                <InputNumber
                  id="minmax-buttons"
                  v-model="body.imageTop"
                  buttonLayout="horizontal"
                  mode="decimal"
                  showButtons
                  :min="0"
                  :max="10000"
                />
              </td>

              <td style="direction: ltr;">
                <InputNumber
                  id="minmax-buttons"
                  v-model="body.imageLeft"
                  buttonLayout="horizontal"
                  mode="decimal"
                  showButtons
                  :min="0"
                  :max="10000"
                />
              </td>

              <td style="direction: ltr;">
                <InputNumber
                  id="minmax-buttons"
                  v-model="body.imageWidth"
                  buttonLayout="horizontal"
                  mode="decimal"
                  showButtons
                  :min="0"
                  :max="10000"
                />
              </td>

              <td style="direction: ltr;">
                <InputNumber
                  id="minmax-buttons"
                  v-model="body.imageHeight"
                  buttonLayout="horizontal"
                  mode="decimal"
                  showButtons
                  :min="0"
                  :max="10000"
                />
              </td>
            </tr>
          </tbody>
        </table>

        <table
          class="table"
          v-if="
            inSelectedArray('academie') ||
              inSelectedArray('name') ||
              inSelectedArray('userType')
          "
        >
          <thead>
            <tr>
              <th>الاعداد</th>
              <th>Top</th>
              <th>Right</th>
            </tr>
          </thead>
          <tbody>
            <tr v-if="inSelectedArray('academie')">
              <th>الاكاديميه</th>
              <td style="direction: ltr;">
                <InputNumber
                  id="minmax-buttons"
                  v-model="body.academieTop"
                  buttonLayout="horizontal"
                  mode="decimal"
                  showButtons
                  :min="0"
                  :max="10000"
                />
              </td>
              <td style="direction: ltr;">
                <InputNumber
                  id="minmax-buttons"
                  v-model="body.academieRight"
                  buttonLayout="horizontal"
                  mode="decimal"
                  showButtons
                  :min="0"
                  :max="10000"
                />
              </td>
            </tr>

            <tr v-if="inSelectedArray('name')">
              <th>الاسم</th>
              <td style="direction: ltr;">
                <InputNumber
                  id="minmax-buttons"
                  v-model="body.nameTop"
                  buttonLayout="horizontal"
                  mode="decimal"
                  showButtons
                  :min="0"
                  :max="10000"
                />
              </td>
              <td style="direction: ltr;">
                <InputNumber
                  id="minmax-buttons"
                  v-model="body.nameRight"
                  buttonLayout="horizontal"
                  mode="decimal"
                  showButtons
                  :min="0"
                  :max="10000"
                />
              </td>
            </tr>

            <tr v-if="inSelectedArray('userType')">
              <th>الصفه</th>
              <td style="direction: ltr;">
                <InputNumber
                  id="minmax-buttons"
                  v-model="body.userTypeTop"
                  buttonLayout="horizontal"
                  mode="decimal"
                  showButtons
                  :min="0"
                  :max="10000"
                />
              </td>
              <td style="direction: ltr;">
                <InputNumber
                  id="minmax-buttons"
                  v-model="body.userTypeRight"
                  buttonLayout="horizontal"
                  mode="decimal"
                  showButtons
                  :min="0"
                  :max="10000"
                />
              </td>
            </tr>
          </tbody>
        </table>

        <div class="mb-3 text-center">
          <Button
            label="تعديل"
            icon="pi pi-pencil"
            class="p-ml-2 p-button-info"
            @click="update()"
            :disabled="disabledAdd"
            v-if="id"
          />
          <Button
            v-else
            label="اضافه جديد"
            icon="pi pi-plus"
            class="p-ml-2 p-button-success"
            @click="save()"
            :disabled="disabledAdd"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      body: {
        image: '',

        name: null,
        selected: ['image', 'academie', 'name', 'userType'],
        imageTop: 57,
        imageLeft: 37,
        imageWidth: 60,
        imageHeight: 90,
        academieRight: 318,
        academieTop: 208,
        nameTop: 167,
        nameRight: 318,
        userTypeTop: 167,
        userTypeRight: 600,
      },
      id: null,
      disabledAdd: false,
    };
  },
  methods: {
    previewImage(ev) {
      this.$file2base64(ev, (image) => {
        this.body.image = image;
      });
    },
    inSelectedArray(val) {
      return this.body.selected.includes(val);
    },
    save() {
      if (this.body.name && this.body.image) {
        this.body.selected = JSON.stringify(this.body.selected);
        this.disabledAdd = true;
        this.$http.post(`certificates`, this.body).then(
          () => {
            this.disabledAdd = false;

            this.$toast.add({
              severity: 'success',
              summary: 'تم بنجاح',
              detail: 'تم الاضافه بنجاح',
              life: 3000,
            });
            this.$router.push('/admin/certificates');
          },
          (err) => {
            this.disabledAdd = false;
            this.body.selected = JSON.parse(this.body.selected);
            this.$toast.add({
              severity: 'error',
              summary: 'هناك خطأ',
              detail: err.response.data.message,
              life: 3000,
            });
          },
        );
      } else {
        this.$toast.add({
          severity: 'error',
          summary: 'هناك خطأ',
          detail: 'يرجى ملاء الحقول',
          life: 3000,
        });
      }
    },
    update() {
      if (this.body.name && this.body.image) {
        this.body.selected = JSON.stringify(this.body.selected);

        this.disabledAdd = true;
        this.$http.put(`certificates/${this.id}`, this.body).then(
          () => {
            this.disabledAdd = false;
            this.$toast.add({
              severity: 'success',
              summary: 'تم بنجاح',
              detail: 'تم التعديل بنجاح',
              life: 3000,
            });
            this.$router.push('/admin/certificates');
          },
          (err) => {
            this.disabledAdd = false;
            this.$toast.add({
              severity: 'error',
              summary: 'هناك خطأ',
              detail: err.response.data.message,
              life: 3000,
            });
          },
        );
      } else {
        this.$toast.add({
          severity: 'error',
          summary: 'هناك خطأ',
          detail: 'يرجى ملاء الحقول',
          life: 3000,
        });
      }
    },
  },
  created() {
    if (this.$route.params.id) {
      this.id = this.$route.params.id;
      this.$http.get(`certificates/${this.id}`).then(
        (res) => {
          this.body = res.data;
          this.body.selected = JSON.parse(res.data.selected);
          this.body.image = this.$baseUploadURL + res.data.image;
        },
        (err) => {
          this.$toast.add({
            severity: 'error',
            summary: 'هناك خطأ',
            detail: err.response.data.message,
            life: 3000,
          });
        },
      );
    }
  },
};
</script>

<style>
.x .psizwxxx {
  width: 19cm;
  height: 13cm;
}
.x .card-body-xxx {
  position: relative;
  padding: 0;
  width: 19cm;
  margin: 0 auto;
}
.x .card-body-xxx p {
  margin: 0;
}

.x .custom-file-label::after {
  right: auto;
  border-radius: 0.25rem 0 0 0.25rem;
  left: 0;
}

.p-inputnumber-buttons-horizontal .p-inputnumber-input {
  width: 100%;
}

.x .p-image .custom-file-input {
  text-align: center;
  width: 40vw;
  position: absolute;
  top: 0;
  height: 105px;
}
</style>
